import {
  NavGroupItem,
  NavItem,
  NavItemInternal,
} from "@/types/navigation-types";
import { useRouter } from "next/router";
import { useMemo } from "react";

export function isGroupItem(
  item: NavItem | NavGroupItem
): item is NavGroupItem {
  return "subItems" in item;
}

export function isInternalItem(item: NavItem): item is NavItemInternal {
  return "nextRoute" in item;
}

export const useNav = () => {
  const router = useRouter();

  return useMemo(() => {
    const navItems = [
      {
        text: "Home",
        nextRoute: { pathname: "/" },
        isActive: ["/"],
      },
      {
        text: "Vaults",
        nextRoute: { pathname: "/vaults" },
        subUrls: ["/vaults", "/vaults/[id]"],
      },
      {
        text: "Farms",
        nextRoute: { pathname: "/farms" },
        subUrls: ["/farms", "/farms/[id]"],
      },
      {
        text: "Stake PIT",
        nextRoute: { pathname: "/stake" },
        subUrls: ["/stake"],
      },
    ].map(({ ...data }) => ({
      ...data,
      isActive: (data?.subUrls ?? []).some((url) =>
        url === "/" ? router.pathname === url : router.pathname.includes(url)
      ),
    }));

    return {
      navItems: navItems as NavItem[],
    };
  }, [router]);
};
